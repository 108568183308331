<template>
    <slotted-tag @close="$emit('close')"
                 :disabled="disabled"
                 tooltip="This is used for Classification work in the Crowd"
                 close-tooltip="Click to stop using this in the Crowd">
        <animated-check v-if="tag.askCrowdClicked && !notAnimated" animated/>
        <i v-else class="symbol-info"></i>
        Crowd Classification
    </slotted-tag>
</template>

<script>

import SlottedTag from "@/components/tags/SlottedTag";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";

export default {
    components: {AnimatedCheck, SlottedTag},

    props: {
        tag: {
            type: Object,
            required: true
        },
        notAnimated: Boolean,
        disabled: Boolean
    }
}

</script>