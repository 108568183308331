<template>
    <slotted-tag @close="$emit('close')"
                 :disabled="disabled"
                 tooltip="Similar mentions will automatically have this tag applied"
                 close-tooltip="Click to stop auto assigning this tag">
        <animated-check v-if="tag.assignClicked && !notAnimated" animated/>
        <i v-else class="symbol-info"></i>
        Auto assigned
    </slotted-tag>
</template>

<script>

import SlottedTag from "@/components/tags/SlottedTag";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";

export default {
    components: {AnimatedCheck, SlottedTag},

    props: {
        tag: {
            type: Object,
            required: true
        },
        notAnimated: Boolean,
        disabled: Boolean
    }
}

</script>