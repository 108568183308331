<template>
    <section class="setup-tag-panel">
        <div class="row-fluid head title">
            <h1>Tags & Custom Tags</h1>
        </div>

        <section v-if="error">
            <i class="symbol-warning"></i> {{ error }}
        </section>
        <loading-message v-if="loading" message="Fetching your tags…"/>

        <section v-else>
            <section v-if="hasRisk || hasPurchase || hasCancel || hasService"
                     class="setup-tag-panel__section">
                <h4>Priority tags</h4>
                <p>
                    Your actionable conversations have the following tags on them.
                </p>
                <rpcs-tag v-if="hasRisk" full code="RISK"></rpcs-tag>
                <rpcs-tag v-if="hasPurchase" full code="PURCHASE"></rpcs-tag>
                <rpcs-tag v-if="hasCancel" full code="CANCEL"></rpcs-tag>
                <rpcs-tag v-if="hasService" full code="SERVICE"></rpcs-tag>
            </section>

            <!--
            <section v-if="cxLists.length || conductLists.length || channelLists.length">
                <h4>Customer Experience and Market Performance</h4>
                <tag-setup-list :tag-parents="cxLists"/>
                <tag-setup-list :tag-parents="conductLists"/>
                <tag-setup-list :tag-parents="channelLists"/>
            </section>

            <section v-if="utilityLists.length">
                <h4>Utility Tags</h4>
                <tag-setup-list :tag-parents="utilityLists"/>
            </section>
            -->

            <section class="setup-tag-panel__section">
                <h4>Custom tags</h4>
                <p>
                    Custom tags are those that you can create and apply to mentions yourself.
                </p>
                <section class="setup-tag-panel__top-controls">
                    <be-button primary
                               :disabled="!!newTag"
                               @click="createTag()">
                        <i class="symbol-add"></i> Create a new tag
                    </be-button>
                    <label v-if="user.admin">
                        <input type="checkbox" v-model="showClassification"/>
                        Show only Crowd classification tags
                    </label>
                    <label v-if="user.admin">
                        <input type="checkbox" v-model="showAutoAssign"/>
                        Show only auto assigned tags
                    </label>
                    <search-input class="setup-tag-panel__top-search"
                                  placeholder="Search your custom tags"
                                  v-model="searchTerm"/>
                </section>

                <section class="setup-tag-panel__table">
                    <div class="setup-tag-panel__table-row setup-tag-panel__table-row--header">
                        <span>
                            Custom Tag
                        </span>
                        <span>
                            Description
                        </span>
                    </div>

                    <section v-if="!tags.length && !newTag" class="setup-tag-panel__no-custom">
                        Your account has no custom tags
                    </section>
                    <section v-else-if="!visibleTags.length && !newTag" class="setup-tag-panel__no-custom">
                        No custom tags match your search terms
                    </section>

                    <div v-for="tag in visibleTags"
                         :key="tag.tag.id"
                         class="setup-tag-panel__table-row"
                         :class="{'setup-tag-panel__table-row--expanded': tag.expanded}"
                         tabindex="-1"
                         :ref="'tagEditor' + tag.tag.id"
                         @keydown.esc="expand(tag, false)"
                         @keydown.enter="possiblySave()"
                         @click="expand(tag, true)">
                        <section v-if="!tag.expanded">
                            <slotted-tag
                                close-tooltip="Click to delete"
                                :disabled="tag.updating"
                                @close="deleteTag(tag)">
                                {{ tag.tag.name }}
                            </slotted-tag>
                            <span class="general-debug-message setup-tag-panel__id" v-if="user.debugMode">
                                [id: {{ tag.tag.id }}]
                            </span>
                        </section>
                        <section v-if="!tag.expanded "
                                 class="setup-tag-panel__description">
                        <span v-if="tag.tag.description">
                            {{ tag.tag.description }}
                        </span>
                            <section v-if="hasMetatags(tag) && user.admin"
                                     class="setup-tag-panel__metatags">
                                <auto-assign-tag v-if="tag.tag.autoAssign"
                                                 :tag="tag"
                                                 :disabled="tag.updating"
                                                 not-animated
                                                 @close="autoAssign(tag, false)"
                                                 class="setup-tag-panel__muted"/>
                                <ask-crowd-tag v-if="tag.tag.forCrowdTopicList"
                                               :tag="tag"
                                               :disabled="tag.updating"
                                               not-animated
                                               @close="askCrowd(tag, false)"
                                               class="setup-tag-panel__muted"/>
                            </section>

                        </section>

                        <section v-if="tag.expanded"
                                 class="setup-tag-panel__info">
                            <header>
                                <span class="setup-tag-panel__header-hover">
                                    <slotted-tag
                                        v-if="tag.tag.name"
                                        close-tooltip="Click to delete"
                                        :disabled="tag.updating"
                                        :no-close="!tag.tag.id"
                                        @close="deleteTag(tag)">
                                        {{ tag.tag.name }}
                                    </slotted-tag>
                                    <span v-if="!tag.editingName"
                                          class="setup-tag-panel__name-instruction" @click.stop="editName(tag)">
                                        Click to <i class="symbol-edit"></i> edit this tag's name
                                    </span>
                                </span>

                                <inline-text-input v-if="tag.editingName"
                                                   class="setup-tag-panel__name-input"
                                                   :ref="'nameEdit' + tag.tag.id"
                                                   v-model="tag.tempName"
                                                   @cancel="cancelEditName(tag)"
                                                   placeholder="Edit the tag's name"
                                                   @ok="updateName(tag)"/>

                                <slotted-tag v-if="warningsFor(tag).length"
                                             no-close
                                             @click.stop="showWarnings(tag)"
                                             tooltip="Click to see warnings" style="cursor: pointer">
                                    <warning-indicator :warnings="warningsFor(tag)"
                                                       :ref="'warnings' + tag.tag.id"
                                                       class="setup-tag-panel__warning-indicator"/>
                                    Has warnings
                                </slotted-tag>

                                <span class="setup-tag-panel__close"
                                      @click.stop="expand(tag, false, true)">
                                    <old-tooltip label="Click to close (esc)"><i class="symbol-close"></i></old-tooltip>
                                </span>
                            </header>
                            <section class="setup-tag-panel__description">
                                <inline-text-input v-if="tag.editingDescription"
                                                   class="setup-tag-panel__description-input"
                                                   :ref="'descriptionEdit' + tag.tag.id"
                                                   v-model="tag.tempDescription"
                                                   @cancel="cancelEditDescription(tag)"
                                                   @ok="updateDescription(tag)"/>
                                <span v-else-if="tag.tag.description"
                                      class="setup-tag-panel__description-present"
                                      @click.stop="editDescription(tag)">
                                {{ tag.tag.description }}. <span class="setup-tag-panel__description-instruction">Click to <i
                                    class="symbol-edit"></i> edit the description</span>
                            </span>
                                <span v-else class="setup-tag-panel__no-description"
                                      @click.stop="editDescription(tag)">
                                This tag has no description. Click here to add one. <i class="symbol-edit"></i>
                            </span>
                            </section>
                        </section>
                        <footer v-if="tag.expanded">
                            <span v-if="tag.tag.created">
                                <span class="setup-tag-panel__label">Created: </span><deq-date :date="tag.tag.created"/>
                            </span>
                            <span v-if="!tag.tag.id && !tag.tag.name">
                                Choose a name for the tag.
                            </span>
                            <span v-else-if="!tag.tag.id">
                                Optionally give this tag a description. Click <em>Create</em> when you're done.
                            </span>


                            <!-- These controls are for tags that have already been created. -->
                            <span v-if="tag.tag.id"
                                  class="setup-tag-panel__controls">
                                <span v-if="user.admin">
                                    <be-button v-if="!tag.tag.autoAssign"
                                               :disabled="tag.updating"
                                               @click.stop="autoAssign(tag, true)"
                                               link>
                                        Auto assign
                                    </be-button>
                                    <auto-assign-tag v-else
                                                     :tag="tag"
                                                     :disabled="tag.updating"
                                                     @close="autoAssign(tag, false)"/>
                                </span>

                                <span v-if="user.admin">
                                    <be-button v-if="!tag.tag.forCrowdTopicList"
                                               :disabled="tag.updating"
                                               @click.stop="askCrowd(tag, true)"
                                               link>
                                        Ask Crowd
                                    </be-button>
                                    <span v-else>
                                        <ask-crowd-tag :tag="tag"
                                                       :disabled="tag.updating"
                                                       @close="askCrowd(tag, false)"/>
                                        at
                                        <popup-menu :ignore-activator-click="tag.updating">
                                            <template #activator>
                                                <slotted-tag no-close :disabled="tag.updating">
                                                    position {{ tag.tag.ordinal }}
                                                </slotted-tag>
                                            </template>

                                            <section class="mini-menu" style="font-family: var(--number-font-family)">
                                                <a @click="updateOrdinal(tag, 0)">0</a>
                                                <a @click="updateOrdinal(tag, 1)">1</a>
                                                <a @click="updateOrdinal(tag, 2)">2</a>
                                                <a @click="updateOrdinal(tag, 3)">3</a>
                                                <a @click="updateOrdinal(tag, 4)">4</a>
                                                <a @click="updateOrdinal(tag, 5)">5</a>
                                                <a @click="updateOrdinal(tag, 6)">6</a>
                                                <a @click="updateOrdinal(tag, 7)">7</a>
                                                <a @click="updateOrdinal(tag, 8)">8</a>
                                                <a @click="updateOrdinal(tag, 9)">9</a>
                                                <a @click="updateOrdinal(tag, 10)">10</a>
                                                <a @click="updateOrdinal(tag, 11)">11</a>
                                                <a @click="updateOrdinal(tag, 12)">12</a>
                                                <a @click="updateOrdinal(tag, 13)">13</a>
                                                <a @click="updateOrdinal(tag, 14)">14</a>
                                                <a @click="updateOrdinal(tag, 15)">15</a>
                                                <a @click="updateOrdinal(tag, 16)">16</a>
                                                <a @click="updateOrdinal(tag, 17)">17</a>
                                                <a @click="updateOrdinal(tag, 18)">18</a>
                                                <a @click="updateOrdinal(tag, 19)">19</a>
                                                <a @click="updateOrdinal(tag, 20)">20</a>
                                            </section>
                                        </popup-menu>
                                    </span>
                                </span>

                                <be-button danger
                                           :disabled="tag.updating"
                                           @click.stop="deleteTag(tag)">
                                    Delete
                                </be-button>
                            </span>

                            <!-- These controls are for new tags -->
                            <span v-else
                                  class="setup-tag-panel__controls">
                                <be-button link
                                           @click="cancelCreation()"
                                           :disabled="tag.updating">
                                    Cancel
                                </be-button>

                                <be-button primary
                                           :disabled="tag.updating || !tag.tag.name || warningsFor(tag).length > 0"
                                           :ref="'saveButton' + tag.tag.id"
                                           @click.stop="saveTag()">
                                    Create
                                </be-button>
                            </span>

                        </footer>
                    </div>
                </section>
            </section>

        </section>

    </section>
</template>

<script>

import LoadingMessage from "@/components/LoadingMessage";
import SlottedTag from "@/components/tags/SlottedTag";
import DeqDate from "@/components/formatters/DeqDate";
import BeButton from "@/components/buttons/BeButton";
import SearchInput from "@/components/inputs/SearchInput";
import {notifyWithHtml, notifyWithText, symbolClass} from "@/app/framework/notifications/Notifications";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import AutoAssignTag from "@/setup/tags/AutoAssignTag";
import AskCrowdTag from "@/setup/tags/AskCrowdTag";
import PopupMenu from "@/components/PopupMenu";
import WarningIndicator from "@/components/WarningIndicator";
import {setTitle} from "@/app/Beef";
import {showAskDialog, showErrorDialog} from "@/app/framework/dialogs/Dialog";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {mapActions, mapMutations, mapState} from "vuex";
import RpcsTag from "@/components/tags/RpcsTag";
import {escapeExpression} from "@/app/utils/StringUtils";

export default {
    components: {
        OldTooltip,
        DeqDate,
        RpcsTag,
        WarningIndicator,
        PopupMenu,
        AskCrowdTag,
        AutoAssignTag, InlineTextInput, SearchInput, BeButton, SlottedTag, LoadingMessage
    },

    props: {
        showTagId: {            // The tag to open at.
            type: Number,
            required: false,
            default: null
        }
    },

    data() {
        return {
            tags: [],
            loading: false,
            error: null,
            cxLists: [],
            conductLists: [],
            channelLists: [],
            utilityLists: [],
            hasRisk: false,
            hasPurchase: false,
            hasCancel: false,
            hasService: false,
            searchTerm: '',
            showClassification: false,
            showAutoAssign: false,
            newTag: null
        }
    },

    computed: {
        ...mapState(['user', 'account']),
        ...mapState({
            accountTags: 'tags'
        }),

        visibleTags() {
            if (!this.newTag) return this.tagsMatchingSearch;
            return [this.newTag, ...this.tagsMatchingSearch];
        },

        tagsMatchingSearch() {
            if (!this.tags?.length) return [];

            const term = this.searchTerm.toLowerCase();

            return this.tags.filter(t => {
                const name = t.tag.name?.toLowerCase() ?? '';
                if (this.showClassification && !t.tag.forCrowdTopicList) return false;
                if (this.showAutoAssign && !t.tag.autoAssign) return false;
                return name.includes(term) || t.expanded;
            })
        }
    },

    mounted() {
        setTitle("Tags");
        this.loadTags();
    },

    methods: {
        ...mapMutations(['setTag']),
        ...mapActions(['refreshTags', 'updateTag']),
        ...mapActions({
            deleteTagFromMash: 'deleteTag',
            createTagOnMash: 'createTag'
        }),

        expand(tag, value, override) {
            value ??= !tag.expanded;
            if (value) tag.expanded = true;
            else if (tag !== this.newTag) tag.expanded = value; // Don't close tags we are creating
            else if (override) this.cancelCreation();

            if (tag.expanded && tag.tag.id) {
                Beef.router.navigate(`/${this.account.code}/setup/tags/${tag.tag.id}`, {trigger: false, replace: true});
            } else {
                Beef.router.navigate(`/${this.account.code}/setup/tags`, {trigger: false, replace: true});
            }
        },
        showWarnings(tag) {
            this.$refs[`warnings${tag.tag.id}`][0].showWarnings();
        },
        warningsFor(tag) {
            const name = tag.tag.name?.toLowerCase() ?? '';
            const warnings = [];
            if (this.tags.find(t => t.tag.name?.toLowerCase() === name && t.tag.id !== tag.tag.id)) {
                warnings.push({
                    id: "SAME_NAME",
                    message: "This tag has the same name as another"
                })
            }
            return warnings;
        },

        /** Sets up a new tag, unsaved tag for the user to edit. */
        createTag() {
            this.newTag = {
                expanded: true,
                updating: false,
                editingName: true,
                tempName: '',
                tag: {
                    id: null,
                    name: null,
                    description: null,
                    autoAssign: null,
                    forCrowdTopicList: null
                }
            }

            this.$nextTick(() => {
                this.$refs[`nameEdit${this.newTag.tag.id}`][0].focus();
            })
        },

        /** Cancels the creation of a new tag */
        cancelCreation() {
            if (!this.newTag) return;

            const old = this.newTag;
            this.newTag = null;
            notifyWithText("New tag creation has been cancelled",
                () => {
                    this.newTag = old;
                    this.newTag.expanded = true;
                },
            "<i class='symbol-tags'></i>")
        },

        /** Saves the tag that is currently being created. */
        async saveTag() {
            if (!this.newTag || this.warningsFor(this.newTag).length) return;

            const tagRef = this.newTag;

            try {
                this.newTag.updating = true;
                const result = await this.createTagOnMash(this.newTag.tag);
                this.newTag.tag = result;

                this.tags.unshift(this.newTag);
                this.newTag = null;

                if (tagRef.expanded && tagRef.tag.id) {
                    Beef.router.navigate(`/${this.account.code}/setup/tags/${tagRef.tag.id}`, {trigger: false, replace: true});
                }

                notifyWithHtml(
                    `The tag <strong>${escapeExpression(result.name)}</strong> has been created`,
                    null,
                    symbolClass("symbol-tags")
                )

            } catch(e) {
                showErrorDialog("There was a problem saving your tag. Please try again in a few minutes");
                console.error(e);
            } finally {
                tagRef.updating = false;
                this.$nextTick(() => this.$refs[`tagEditor${tagRef.tag.id}`][0].focus());
            }
        },

        async possiblySave() {
            if (!this?.newTag?.name) {
                await this.saveTag();
            }
        },

        hasMetatags(tag) {
            return tag.tag.autoAssign || tag.tag.forCrowdTopicList;
        },

        /** Fetches tag data from mash and sorts it appropriately for display to the user. */
        async loadTags() {
            try {
                this.loading = true;
                this.hasRisk = false;
                this.hasPurchase = false;
                this.hasCancel = false;
                this.hasService = false;

                await this.refreshTags(true);
                const tags = [];
                const cxLists = [];
                const conductLists = [];
                const channelLists = [];
                const utilityLists = [];

                for (const tag of this.accountTags) {
                    if (tag.namespace === "tag" && !tag.name.startsWith('Retrosent 2')) {
                        tags.push({
                            expanded: false,
                            tag: tag,
                            updating: false
                        })
                    }

                    if (tag._parent?.segmentType) {
                        // All accounts have risk and service, since we have global tags
                        // that use these for prioritisation in Engage. But accounts that don't
                        // have conduct don't have engage, so these engage tags are meaningless to them.
                        // Here we filter out global tags that aren't part of an appropriate segment type.
                        if (tag.flag === "RISK") {
                            this.hasRisk = true
                        }
                        if (tag.flag === "PURCHASE") this.hasPurchase = true;
                        if (tag.flag === "CANCEL") this.hasCancel = true;
                        if (tag.flag === "SERVICE") this.hasService = true;
                    }


                    if (tag.namespace === "segment_list") {
                        switch (tag.segmentType?.id) {
                            case "CX_LIST":
                                cxLists.push(tag);
                                break;
                            case "CONDUCT_LIST":
                                conductLists.push(tag);
                                break;
                            case "CHANNEL_LIST":
                                channelLists.push(tag);
                                break;
                            default:
                                utilityLists.push(tag);
                        }
                    }
                }

                this.tags = tags;
                this.cxLists = cxLists;
                this.conductLists = conductLists;
                this.channelLists = channelLists;
                this.utilityLists = utilityLists;

                if (this.showTagId) {
                    this.$nextTick(() => {
                        const found = this.tags.find(t => t.tag.id === this.showTagId);
                        if (found) {
                            this.expand(found);
                            this.$nextTick(() => {
                                const editor = this.$refs['tagEditor' + found.tag.id];
                                if (editor?.length) {
                                    setTimeout(() => editor[0].scrollIntoView({behavior: "smooth", block: "center"}));
                                }
                            })
                        }
                    });
                }
            } catch (e) {
                console.error(e);
                this.error = "There was a problem loading your tags";
            } finally {
                this.loading = false;
            }
        },

        editName(tag) {
            if (tag.updating) return;

            this.$set(tag, "editingName", true);
            this.$set(tag, "tempName", tag.tag.name ?? '');

            const name = `nameEdit${tag.tag.id}`
            this.$nextTick(() => this.$refs[name][0].focus());
        },

        cancelEditName(tag) {
            if (!tag.tag.name && !tag.tempName && this.newTag === tag) {
                this.cancelCreation();
                return;
            }
            if (!tag.tag.name || !tag.tempName) return;
            tag.editingName = false;
            tag.tempName = '';
            this.$nextTick(() => this.$refs[`tagEditor${tag.tag.id}`][0].focus());
        },

        async updateName(tag) {
            if (!tag.tempName) return; // Can't set name to empty
            if (tag.updating) return;

            tag.updating = true;
            tag.editingName = false;
            const oldName = tag.tag.name;
            if (tag.tag.id) {
                this.setTag( {id: tag.tag.id, name: tag.tempName});
            } else {
                tag.tag.name = tag.tempName; // Hasn't been created yet.
            }

            try {
                if (tag.tag.name === oldName) return; // Job done.

                // Here, we are busy creating a new tag. Name clash warnings etc can be handled
                // interactively by the user.
                if (!tag.tag.id) return;

                // Here we cancel and give an error if another tag has the same name.
                if (this.warningsFor(tag).find(w => w.id === "SAME_NAME")) {
                    this.updating = false;
                    showErrorDialog(`There is another tag already called "${tag.tempName}". Please use another.`);
                    tag.tag.name = oldName;
                    tag.tempName = '';
                    return;
                }

                await this.updateTag(tag.tag);

                notifyWithHtml(
                    "The tag's name has been updated",
                    () => {
                        tag.tempName = oldName ?? '';
                        this.updateName(tag);
                    },
                    symbolClass('symbol-tags')
                )

            } catch (e) {
                console.error("There was an error updating the tag", e);
                showErrorDialog("There was an error updating this tag");
                this.setTag( {id: tag.tag.id, name: oldName});
            } finally {
                tag.updating = false;
                this.$nextTick(() => this.$refs[`tagEditor${tag.tag.id}`][0].focus());
            }
        },

        async updateOrdinal(tag, ordinal) {
            if (tag.updating) return;

            tag.updating = true;
            const oldOrdinal = tag.tag.ordinal ?? 0;
            if (tag.tag.id) {
                await this.setTag({id: tag.tag.id, ordinal});
            } else {
                tag.tag.ordinal = ordinal; // New tag.
            }

            try {
                await this.updateTag(tag.tag);

                notifyWithHtml(
                    "The tag's position in the Crowd classification jobs has been updated",
                    () => {
                        this.updateOrdinal(tag, oldOrdinal);
                    },
                    symbolClass('symbol-tags')
                )
            } catch (e) {
                console.error("There was an error updating the tag", e);
                showErrorDialog("There was an error updating this tag");
                this.setTag({id: tag.tag.id, ordinal: oldOrdinal});
            } finally {
                tag.updating = false;
                this.$nextTick(() => this.$refs[`tagEditor${tag.tag.id}`][0].focus());
            }
        },

        editDescription(tag) {
            if (tag.updating) return;

            this.$set(tag, "editingDescription", true);
            this.$set(tag, "tempDescription", tag.tag.description ?? '');

            const name = `descriptionEdit${tag.tag.id}`
            this.$nextTick(() => this.$refs[name][0].focus());
        },

        cancelEditDescription(tag) {
            tag.editingDescription = false;
            tag.tempDescription = '';
            this.$nextTick(() => this.$refs[`tagEditor${tag.tag.id}`][0].focus());
        },

        async updateDescription(tag) {
            if (tag.updating) return;

            tag.updating = true;
            tag.editingDescription = false;
            const oldDescription = tag.tag.description;
            if (tag.tag.id) {
                this.setTag( {id: tag.tag.id, description: tag.tempDescription});
            } else {
                tag.tag.description = tag.tempDescription;
            }

            try {
                if (tag.tag.description === oldDescription) return; // Job done.

                if (!tag.tag.id) return; // busy creating a new tag.
                await this.updateTag(tag.tag);

                notifyWithHtml(
                    "The tag's description has been updated",
                    () => {
                        tag.tempDescription = oldDescription ?? '';
                        this.updateDescription(tag);
                    },
                    symbolClass('symbol-tags')
                )
            } catch (e) {
                console.error("There was an error updating the tag", e);
                showErrorDialog("There was an error updating this tag");
                this.setTag( {id: tag.tag.id, description: oldDescription});
            } finally {
                tag.updating = false;
                this.$nextTick(() => this.$refs[`tagEditor${tag.tag.id}`][0].focus());
            }

        },

        async autoAssign(tag, value, noConfirm) {
            if (tag.updating) return;

            const confirm = noConfirm || value || await showAskDialog("Ask the Crowd?",
                `Would you like the Crowd to stop using this tag in classification work?`, true);

            if (!confirm) return;

            tag.updating = true;
            const oldValue = !!tag.tag.autoAssign;
            if (tag.tag.id) {
                this.setTag({id: tag.tag.id, autoAssign: value});
            } else {
                tag.tag.autoAssign = value; // New tag.
            }
            this.$set(tag, "assignClicked", value);

            try {
                await this.updateTag(tag.tag);

                const message = value
                    ? "This tag will now be automatically assigned by DataEQ"
                    : "This tag will no longer be automatically assigned by DataEQ"

                notifyWithHtml(
                    message,
                    () => this.autoAssign(tag, !value, true),
                    symbolClass('symbol-tags')
                )
            } catch (e) {
                console.error("There was an error updating the tag", e);
                showErrorDialog("There was an error updating this tag");
                this.setTag({id: tag.tag.id, autoAssign: oldValue});
            } finally {
                tag.updating = false;
                this.$nextTick(() => this.$refs[`tagEditor${tag.tag.id}`][0].focus());
            }
        },

        async askCrowd(tag, value, noConfirm) {
            if (tag.updating) return;

            const confirm = noConfirm || value || await showAskDialog("Ask the Crowd?",
                `Would you like the Crowd to stop using this tag in classification work?`, true);

            if (!confirm) return;

            tag.updating = true;
            const oldValue = tag.tag.forCrowdTopicList;
            this.setTag({id: tag.tag.id, forCrowdTopicList: value});
            this.$set(tag, "askCrowdClicked", value);

            try {
                await this.updateTag(tag.tag);

                const message = value
                    ? "This tag will now be used for classification work in the Crowd"
                    : "This tag will no longer be used for classification work in the Crowd"

                notifyWithHtml(
                    message,
                    () => this.askCrowd(tag, !value, true),
                    symbolClass('symbol-tags')
                )
            } catch (e) {
                console.error("There was an error updating the tag", e);
                showErrorDialog("There was an error updating this tag");
                this.setTag({id: tag.tag.id, forCrowdTopicList: oldValue});
            } finally {
                tag.updating = false;
                this.$nextTick(() => this.$refs[`tagEditor${tag.tag.id}`][0].focus());
            }
        },

        async deleteTag(tag) {
            if (tag.updating) return;

            const confirm = await showAskDialog("Delete a tag",
                `Would you like to permanently remove the tag "${tag.tag.name}" from all mentions?`, true);
            if (!confirm) return;

            try {
                const tagRef = tag.tag;
                tag.updating = true;
                const name = tag.tag.name;
                await this.deleteTagFromMash(tag.tag);
                const index = this.tags.indexOf(tag);
                this.tags.splice(index, 1);

                Beef.router.navigate(`/${this.account.code}/setup/tags`, {trigger: false, replace: true});
                notifyWithHtml(
                    `The tag <strong>${escapeExpression(name)}</strong> has been deleted`,
                    async () => {
                        let tag = await this.createTagOnMash(tagRef);
                        let result = {tag};
                        this.tags.unshift(result);
                        this.expand(result);

                        this.$nextTick(() => {
                            const editor = this.$refs['tagEditor' + tag.id];
                            if (editor?.length) {
                                setTimeout(() => editor[0].scrollIntoView({behavior: "smooth", block: "center"}));
                            }
                        });

                        notifyWithHtml(
                            `The tag <strong>${escapeExpression(tag.name)}</strong> has been undeleted`,
                            null,
                            symbolClass('symbol-tags')
                        )
                    },
                    symbolClass('symbol-tags')
                )
            } catch (e) {
                console.error("There was an error deleting a tag", e);
                showErrorDialog("There was an error deleting this tag");
            } finally {
                tag.updating = false;
            }
        },
    }
}

</script>

<style scoped lang="sass">

    .setup-tag-panel
        --border: 1px solid #1a1a1a
        --table-width: 1200px

    .setup-tag-panel__section
        > p
            color: var(--be-colour-text-dark)

    .setup-tag-panel__section + .setup-tag-panel__section
        margin-top: 40px

    .setup-tag-panel__id
        float: right

    .setup-tag-panel__table
        display: flex
        flex-direction: column

    .setup-tag-panel__top-controls
        display: flex
        align-items: center
        max-width: var(--table-width)

        > *
            margin-right: 10px

        .setup-tag-panel__top-search
            flex: 1 1
            margin-right: 0

        > label
            color: var(--be-colour-muted-text-dark)
            margin-bottom: 0

            input
                margin-top: 0
                margin-bottom: 2px

    .setup-tag-panel__table-row
        cursor: pointer
        display: grid
        max-width: var(--table-width)
        grid-template-columns: 400px 1fr
        transition-property: background, margin
        transition-duration: var(--transition-duration)
        background: #333
        min-height: 2em
        border-top: var(--border)
        border-bottom: var(--border)
        border-right: var(--border)

        & + .setup-tag-panel__table-row
            border-top: none

        > section
            display: block
            padding: 5px
            border-left: var(--border)

        .setup-tag-panel__description
            color: var(--be-colour-text-dark)

        .setup-tag-panel__description-input
            width: 50%

        &--expanded
            cursor: default

        &:not(&--expanded):hover
            background: var(--background-menu-hover)

            .setup-tag-panel__description
                color: white

            .general-debug-message
                color: white


        &:focus
            outline: none

        &--header
            cursor: default
            position: sticky
            top: 40px
            z-index: 10
            border: none
            color: var(--be-colour-text-dark)
            text-align: center

            span
                background: #222
                box-sizing: border-box
                border-top: var(--border)
                border-right: var(--border)
                padding: 5px 10px

                &:first-child
                    border-top-left-radius: 3px
                    border-left: var(--border)

                &:last-child
                    border-top-right-radius: 3px

        .setup-tag-panel__info
            position: relative
            transition: padding var(--transition-duration)
            border: none
            padding: 0

            .setup-tag-panel__close
                transition: color var(--transition-duration)
                position: absolute
                right: 10px
                top: 10px
                color: var(--be-colour-text-dark)
                cursor: pointer
                width: unset
                height: unset

                &:hover
                    color: var(--be-colour-text-dark__hover)


        &--expanded
            display: grid
            grid-template-areas: "info" "footer"
            grid-template-rows: 1fr auto
            grid-template-columns: 1fr

            border: var(--border) !important
            min-height: 100px
            margin-top: 20px
            margin-bottom: 20px
            background: #444
            box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4)

            .setup-tag-panel__info
                grid-area: info
                padding: 20px

                header
                    margin-bottom: 20px

                    .be-tag
                        margin-right: 10px

                    .setup-tag-panel__name-input
                        width: 40%
                        display: inline-flex

                    .setup-tag-panel__name-instruction
                        transition-property: color, opacity
                        transition-duration: var(--transition-duration)
                        opacity: 0
                        max-width: 80%
                        cursor: pointer

                        &:hover
                            color: var(--be-colour-text-dark__hover)
                            opacity: 1

                    .setup-tag-panel__header-hover
                        &:hover
                            .setup-tag-panel__name-instruction
                                opacity: 1

            .setup-tag-panel__description-present
                transition: color var(--transition-duration)
                cursor: pointer

                .setup-tag-panel__description-instruction
                    opacity: 0
                    transition: opacity var(--transition-duration)

                &:hover
                    color: var(--be-colour-text-dark__hover)

                    .setup-tag-panel__description-instruction
                        opacity: 1

            .setup-tag-panel__no-description
                transition: color var(--transition-duration)
                color: var(--be-colour-muted-text-dark)
                font-style: italic

                &:hover
                    color: var(--be-colour-text-dark__hover)

            .setup-tag-panel__label
                color: var(--be-colour-text-dark)

            footer
                grid-area: footer
                display: flex
                align-items: center
                padding: 10px
                border-top: var(--border)
                background: #3d3d3d

                .setup-tag-panel__controls
                    margin-left: auto
                    display: flex

                    .be-tag
                        margin-right: 10px

    .setup-tag-panel__muted
        color: var(--be-colour-muted-text-dark)
        & ::v-deep .symbol-info
            color: var(--be-colour-mid-grey)

    .setup-tag-panel__no-custom
        font-style: italic
        text-align: center
        border: thin solid #222
        color: var(--be-colour-text-dark)
        background: #333
        padding: 10px 0


    .setup-tag-panel__metatags
        .be-tag + .be-tag
            margin-left: 10px


    .setup-tag-panel__metatags:not(:first-child)
        margin-top: 10px

        .be-tag + .be-tag
            margin-left: 10px


    .setup-tag-panel__warning-indicator
        display: inline-block
        & ::v-deep .symbol-warning
            font-size: 1em !important


</style>
